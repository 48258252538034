const SoundFiles = {
    "A0": require("../assets/sounds/acoustic_grand_piano_A0.mp3"),
    "A1": require("../assets/sounds/acoustic_grand_piano_A1.mp3"),
    "A2": require("../assets/sounds/acoustic_grand_piano_A2.mp3"),
    "A3": require("../assets/sounds/acoustic_grand_piano_A3.mp3"),
    "A4": require("../assets/sounds/acoustic_grand_piano_A4.mp3"),
    "A5": require("../assets/sounds/acoustic_grand_piano_A5.mp3"),
    "A6": require("../assets/sounds/acoustic_grand_piano_A6.mp3"),
    "A7": require("../assets/sounds/acoustic_grand_piano_A7.mp3"),

    "Ab1": require("../assets/sounds/acoustic_grand_piano_Ab1.mp3"),
    "Ab2": require("../assets/sounds/acoustic_grand_piano_Ab2.mp3"),
    "Ab3": require("../assets/sounds/acoustic_grand_piano_Ab3.mp3"),
    "Ab4": require("../assets/sounds/acoustic_grand_piano_Ab4.mp3"),
    "Ab5": require("../assets/sounds/acoustic_grand_piano_Ab5.mp3"),
    "Ab6": require("../assets/sounds/acoustic_grand_piano_Ab6.mp3"),
    "Ab7": require("../assets/sounds/acoustic_grand_piano_Ab7.mp3"),

    "B0": require("../assets/sounds/acoustic_grand_piano_B0.mp3"),
    "B1": require("../assets/sounds/acoustic_grand_piano_B1.mp3"),
    "B2": require("../assets/sounds/acoustic_grand_piano_B2.mp3"),
    "B3": require("../assets/sounds/acoustic_grand_piano_B3.mp3"),
    "B4": require("../assets/sounds/acoustic_grand_piano_B4.mp3"),
    "B5": require("../assets/sounds/acoustic_grand_piano_B5.mp3"),
    "B6": require("../assets/sounds/acoustic_grand_piano_B6.mp3"),
    "B7": require("../assets/sounds/acoustic_grand_piano_B7.mp3"),

    "Bb0": require("../assets/sounds/acoustic_grand_piano_Bb0.mp3"),
    "Bb1": require("../assets/sounds/acoustic_grand_piano_Bb1.mp3"),
    "Bb2": require("../assets/sounds/acoustic_grand_piano_Bb2.mp3"),
    "Bb3": require("../assets/sounds/acoustic_grand_piano_Bb3.mp3"),
    "Bb4": require("../assets/sounds/acoustic_grand_piano_Bb4.mp3"),
    "Bb5": require("../assets/sounds/acoustic_grand_piano_Bb5.mp3"),
    "Bb6": require("../assets/sounds/acoustic_grand_piano_Bb6.mp3"),
    "Bb7": require("../assets/sounds/acoustic_grand_piano_Bb7.mp3"),

    "C1": require("../assets/sounds/acoustic_grand_piano_C1.mp3"),
    "C2": require("../assets/sounds/acoustic_grand_piano_C2.mp3"),
    "C3": require("../assets/sounds/acoustic_grand_piano_C3.mp3"),
    "C4": require("../assets/sounds/acoustic_grand_piano_C4.mp3"),
    "C5": require("../assets/sounds/acoustic_grand_piano_C5.mp3"),
    "C6": require("../assets/sounds/acoustic_grand_piano_C6.mp3"),
    "C7": require("../assets/sounds/acoustic_grand_piano_C7.mp3"),
    "C8": require("../assets/sounds/acoustic_grand_piano_C8.mp3"),

    "D1": require("../assets/sounds/acoustic_grand_piano_D1.mp3"),
    "D2": require("../assets/sounds/acoustic_grand_piano_D2.mp3"),
    "D3": require("../assets/sounds/acoustic_grand_piano_D3.mp3"),
    "D4": require("../assets/sounds/acoustic_grand_piano_D4.mp3"),
    "D5": require("../assets/sounds/acoustic_grand_piano_D5.mp3"),
    "D6": require("../assets/sounds/acoustic_grand_piano_D6.mp3"),
    "D7": require("../assets/sounds/acoustic_grand_piano_D7.mp3"),

    "Db1": require("../assets/sounds/acoustic_grand_piano_Db1.mp3"),
    "Db2": require("../assets/sounds/acoustic_grand_piano_Db2.mp3"),
    "Db3": require("../assets/sounds/acoustic_grand_piano_Db3.mp3"),
    "Db4": require("../assets/sounds/acoustic_grand_piano_Db4.mp3"),
    "Db5": require("../assets/sounds/acoustic_grand_piano_Db5.mp3"),
    "Db6": require("../assets/sounds/acoustic_grand_piano_Db6.mp3"),
    "Db7": require("../assets/sounds/acoustic_grand_piano_Db7.mp3"),

    "E1": require("../assets/sounds/acoustic_grand_piano_E1.mp3"),
    "E2": require("../assets/sounds/acoustic_grand_piano_E2.mp3"),
    "E3": require("../assets/sounds/acoustic_grand_piano_E3.mp3"),
    "E4": require("../assets/sounds/acoustic_grand_piano_E4.mp3"),
    "E5": require("../assets/sounds/acoustic_grand_piano_E5.mp3"),
    "E6": require("../assets/sounds/acoustic_grand_piano_E6.mp3"),
    "E7": require("../assets/sounds/acoustic_grand_piano_E7.mp3"),

    "Eb1": require("../assets/sounds/acoustic_grand_piano_Eb1.mp3"),
    "Eb2": require("../assets/sounds/acoustic_grand_piano_Eb2.mp3"),
    "Eb3": require("../assets/sounds/acoustic_grand_piano_Eb3.mp3"),
    "Eb4": require("../assets/sounds/acoustic_grand_piano_Eb4.mp3"),
    "Eb5": require("../assets/sounds/acoustic_grand_piano_Eb5.mp3"),
    "Eb6": require("../assets/sounds/acoustic_grand_piano_Eb6.mp3"),
    "Eb7": require("../assets/sounds/acoustic_grand_piano_Eb7.mp3"),

    "F1": require("../assets/sounds/acoustic_grand_piano_F1.mp3"),
    "F2": require("../assets/sounds/acoustic_grand_piano_F2.mp3"),
    "F3": require("../assets/sounds/acoustic_grand_piano_F3.mp3"),
    "F4": require("../assets/sounds/acoustic_grand_piano_F4.mp3"),
    "F5": require("../assets/sounds/acoustic_grand_piano_F5.mp3"),
    "F6": require("../assets/sounds/acoustic_grand_piano_F6.mp3"),
    "F7": require("../assets/sounds/acoustic_grand_piano_F7.mp3"),

    "G1": require("../assets/sounds/acoustic_grand_piano_G1.mp3"),
    "G2": require("../assets/sounds/acoustic_grand_piano_G2.mp3"),
    "G3": require("../assets/sounds/acoustic_grand_piano_G3.mp3"),
    "G4": require("../assets/sounds/acoustic_grand_piano_G4.mp3"),
    "G5": require("../assets/sounds/acoustic_grand_piano_G5.mp3"),
    "G6": require("../assets/sounds/acoustic_grand_piano_G6.mp3"),
    "G7": require("../assets/sounds/acoustic_grand_piano_G7.mp3"),
    
    "Gb1": require("../assets/sounds/acoustic_grand_piano_Gb1.mp3"),
    "Gb2": require("../assets/sounds/acoustic_grand_piano_Gb2.mp3"),
    "Gb3": require("../assets/sounds/acoustic_grand_piano_Gb3.mp3"),
    "Gb4": require("../assets/sounds/acoustic_grand_piano_Gb4.mp3"),
    "Gb5": require("../assets/sounds/acoustic_grand_piano_Gb5.mp3"),
    "Gb6": require("../assets/sounds/acoustic_grand_piano_Gb6.mp3"),
    "Gb7": require("../assets/sounds/acoustic_grand_piano_Gb7.mp3"),
}

export default SoundFiles